import React from 'react';
import { Navigate, Route as RouterRoute, Routes as RouterRoutes } from 'react-router-dom';
import { useAuth } from './App';
import AppLayout from './layouts/AppLayout';
import MarComLayout from './layouts/MarComLayout';
import HomePage from './pages/HomePage';
import AccountsPage from './pages/AccountsPage';
import SecurityPage from './pages/SecurityPage';
import PrivacyPage from './pages/PrivacyPage';
import HelpPage from './pages/HelpPage';
import DashboardPage from './pages/DashboardPage';
import OnboardingPage from './pages/OnboardingPage';
import TransactionsPage from './pages/TransactionsPage';
import ImpersonationPage from './pages/ImpersonationPage';
import AboutUsPage from './pages/AboutUsPage';
import UserInterfacePage from './pages/UserInterfacePage';
import PlansPage, { PlansPageTab } from './pages/PlansPage';
import SettingsPage from './pages/SettingsPage';
import WelcomePage from './pages/WelcomePage';
import WelcomeLayout from './layouts/WelcomeLayout';

const Route = Object.freeze({
  AboutUs: '/about',
  Accounts: '/accounts',
  Budget: '/:timescale/:date',
  Goal: '/goals/:planId',
  Goals: '/goals',
  Help: '/help',
  Impersonate: '/impersonate',
  Onboarding: '/onboarding',
  Privacy: '/privacy',
  Recoveries: '/recoveries',
  Recovery: '/recoveries/:planId',
  Root: '/',
  Security: '/security',
  Settings: '/settings',
  Transaction: '/transactions/:transactionId',
  Transactions: '/transactions',
  UserInterface: '/ui',
  Welcome: '/welcome/:step',
});

/**
 * Generates a route by replacing the parameters in the static route string with corresponding values passed in with the same name.
 * @param route - The route string containing parameter placeholders in the format `:paramName`.
 * @param params - An object containing key-value pairs of parameter names and their values (without the colon).
 * @returns The generated route string with the parameter placeholders replaced by their values.
 * @throws Error if a parameter in the route string does not have a corresponding value in the params object.
 */
function generateRoute(route: string, params: Record<string, string | number> = {}) {
  const expectedParamCount = route.split(':').length - 1;
  const actualParamCount = Object.keys(params).length;

  if (expectedParamCount !== actualParamCount)
    throw new Error(`Route ${route} expected ${expectedParamCount} parameter(s) but got ${actualParamCount}`);

  return Object.entries(params).reduce((acc, [key, value]) => {
    if (!acc.includes(`:${key}`)) throw new Error(`Route ${route} does not have a parameter :${key}`);
    if (value === null || value === undefined || value.toString().length === 0)
      throw new Error(`A value for parameter ${key} in ${route} is required but was not provided`);
    return acc.replace(`:${key}`, value.toString());
  }, route);
}

function RequireAuth({ children, otherwise }: { children: React.ReactNode; otherwise?: React.ReactNode }): any {
  const { user } = useAuth();

  if (!user) {
    if (otherwise) return otherwise;
    return <Navigate to={Route.Root} replace />;
  }

  return children;
}

function RequireOnboarding({ children }) {
  const { isOnboarding } = useAuth();

  if (isOnboarding) {
    return <Navigate to={Route.Onboarding} replace />;
  }

  return children;
}

function Routes() {
  const { user } = useAuth();

  return (
    <RouterRoutes>
      <RouterRoute element={<WelcomeLayout />}>
        <RouterRoute path={Route.Welcome} element={<WelcomePage />} />
      </RouterRoute>
      <RouterRoute element={user ? <AppLayout /> : <MarComLayout />}>
        <RouterRoute path={Route.Help} element={<HelpPage />} />
        <RouterRoute path={Route.Privacy} element={<PrivacyPage />} />
        <RouterRoute path={Route.Security} element={<SecurityPage />} />
        <RouterRoute path={Route.AboutUs} element={<AboutUsPage />} />
        <RouterRoute
          path={Route.Settings}
          element={
            <RequireAuth otherwise={<HomePage />}>
              <RequireOnboarding>
                <SettingsPage />
              </RequireOnboarding>
            </RequireAuth>
          }
        />
        <RouterRoute
          path={Route.Root}
          element={
            <RequireAuth otherwise={<HomePage />}>
              <RequireOnboarding>
                <DashboardPage />
              </RequireOnboarding>
            </RequireAuth>
          }
        />
        <RouterRoute
          path={Route.Onboarding}
          element={
            <RequireAuth>
              <OnboardingPage />
            </RequireAuth>
          }
        />
        <RouterRoute
          path={Route.Accounts}
          element={
            <RequireAuth>
              <RequireOnboarding>
                <AccountsPage />
              </RequireOnboarding>
            </RequireAuth>
          }
        />
        <RouterRoute
          path={Route.Goal}
          element={
            <RequireAuth>
              <RequireOnboarding>
                <PlansPage tab={PlansPageTab.Goals} />
              </RequireOnboarding>
            </RequireAuth>
          }
        />
        <RouterRoute
          path={Route.Goals}
          element={
            <RequireAuth>
              <RequireOnboarding>
                <PlansPage tab={PlansPageTab.Goals} />
              </RequireOnboarding>
            </RequireAuth>
          }
        />
        <RouterRoute
          path={Route.Recovery}
          element={
            <RequireAuth>
              <RequireOnboarding>
                <PlansPage tab={PlansPageTab.Recoveries} />
              </RequireOnboarding>
            </RequireAuth>
          }
        />
        <RouterRoute
          path={Route.Recoveries}
          element={
            <RequireAuth>
              <RequireOnboarding>
                <PlansPage tab={PlansPageTab.Recoveries} />
              </RequireOnboarding>
            </RequireAuth>
          }
        />
        <RouterRoute
          path={Route.Transaction}
          element={
            <RequireAuth>
              <TransactionsPage />
            </RequireAuth>
          }
        />
        <RouterRoute
          path={Route.Transactions}
          element={
            <RequireAuth>
              <TransactionsPage />
            </RequireAuth>
          }
        />
        <RouterRoute
          path={Route.Impersonate}
          element={
            <RequireAuth>
              <ImpersonationPage />
            </RequireAuth>
          }
        />
        <RouterRoute path={Route.UserInterface} element={<UserInterfacePage />} />
        <RouterRoute
          path={Route.Budget}
          element={
            <RequireAuth>
              <RequireOnboarding>
                <DashboardPage />
              </RequireOnboarding>
            </RequireAuth>
          }
        />
      </RouterRoute>
    </RouterRoutes>
  );
}

export { Route, generateRoute };
export default Routes;
