import React, { useEffect } from 'react';
import { ComponentsSeparatedByDots } from './DotSeparator';
import TransactionDate from './TransactionDate';
import TransactionPending from './TransactionPending';
import TransactionAmountButton from './TransactionAmountButton';
import { Form, Stack } from 'react-bootstrap';
import toggleTransactionField from '../utils/toggleTransactionField';
import { useAuth } from '../App';
import DollarAmount from './DollarAmount';
import { BsArrowRepeat, BsArrowReturnRight, BsClipboardData, BsCreditCard } from 'react-icons/bs';
import { Link, useParams } from 'react-router-dom';
import { Route, generateRoute } from '../Routes';
import { AccountDocData, TransactionDocData } from '../types';

interface LabelOrDivProps {
  useLabel: boolean;
  children: React.ReactNode;
  [key: string]: any;
}

function LabelOrDiv({ useLabel, children, ...props }: LabelOrDivProps) {
  return useLabel ? <label {...props}>{children}</label> : <div {...props}>{children}</div>;
}

interface TransactionRowProps {
  transaction: TransactionDocData;
  account: any;
  runningBalance: number | boolean;
  showCategoryStartingAt?: number;
  toggleRecurringOnly?: boolean;
}

function TransactionRow({
  transaction,
  account,
  runningBalance,
  showCategoryStartingAt = 0,
  toggleRecurringOnly = false,
}: TransactionRowProps) {
  const { uid } = useAuth();

  const { amount, category, isGuessedCC, isRecurring, name, plaidRecurringStreamId, transactionId, planId } = transaction;

  return (
    <LabelOrDiv useLabel={toggleRecurringOnly} style={{ display: 'flex' }}>
      {toggleRecurringOnly && (
        <div>
          <Form.Check type="switch" defaultChecked={isRecurring} onChange={() => toggleTransactionField(uid, transaction, 'isRecurring')} />
        </div>
      )}
      <div style={{ flex: 1 }}>
        <div style={{ float: 'right', marginLeft: 10 }}>
          {toggleRecurringOnly ? (
            <DollarAmount amount={amount} color showDecimals />
          ) : (
            <TransactionAmountButton transaction={transaction} />
          )}

          {typeof runningBalance === 'number' && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'right',
                alignItems: 'center',
                marginTop: 2,
                gap: 4,
              }}
              className="small-font text-muted"
            >
              <BsArrowReturnRight />
              <DollarAmount amount={runningBalance} color />
            </div>
          )}
        </div>
        <div
          title={transactionId}
          style={{
            overflowWrap: 'anywhere',
          }}
        >
          {name}
        </div>

        <div className="small-font text-muted">
          <div>
            <TransactionRowMetadata transaction={transaction} account={account} />
          </div>
          {category && (
            <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
              {category.slice(showCategoryStartingAt).join(' / ')}
              {isGuessedCC && <BsCreditCard title="Automatically ignored as credit card payment" />}
              {plaidRecurringStreamId && <BsArrowRepeat title="Automatically identified as recurring" />}
            </div>
          )}
          {planId && (
            <Stack direction="horizontal" gap={1}>
              <BsClipboardData />
              <Link to={generateRoute(Route.Recovery, { planId })} style={{ color: 'inherit' }}>
                Amortized
              </Link>
            </Stack>
          )}
        </div>
      </div>
    </LabelOrDiv>
  );
}

interface TransactionRowContainerProps {
  children: React.ReactNode;
  [key: string]: any;
}

function TransactionRowContainer({ children, ...props }: TransactionRowContainerProps) {
  const { transactionId } = useParams<{ transactionId: string }>();

  useEffect(() => {
    const transactionElement = document.getElementById(transactionId);
    if (!transactionElement) return;
    transactionElement.scrollIntoView();
    transactionElement.classList.add('highlight');
  }, [transactionId]);

  return (
    <div className="transaction list-item-with-border" {...props}>
      {children}
    </div>
  );
}

type TransactionRowsProps = {
  transactions: TransactionDocData[];
  accountsById: any;
  endingBalance?: number;
  showCategoryStartingAt?: number;
  toggleRecurringOnly?: boolean;
  [key: string]: any;
};

function TransactionRows({ transactions, accountsById, endingBalance, ...props }: TransactionRowsProps) {
  // keep track so we can build the running balance using the previous transaction's amount
  let previousTransactionAmount = 0;
  let runningBalance = endingBalance;

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {transactions.map((transaction) => {
        const shouldCalculateRunningBalance = endingBalance && !(transaction.isIgnored || transaction.isRecurring || transaction.planId);

        if (shouldCalculateRunningBalance) {
          runningBalance -= previousTransactionAmount;
          previousTransactionAmount = transaction.amount;
        }

        return (
          <TransactionRowContainer id={transaction.transactionId} key={transaction.transactionId}>
            <TransactionRow
              transaction={transaction}
              account={accountsById[transaction.accountId]}
              runningBalance={shouldCalculateRunningBalance && runningBalance}
              {...props}
            />
          </TransactionRowContainer>
        );
      })}
    </div>
  );
}

function TransactionRowMetadata({ transaction, account }: { transaction: TransactionDocData; account: AccountDocData }) {
  return (
    <ComponentsSeparatedByDots
      components={[
        transaction.pending && <TransactionPending />,
        <TransactionDate date={transaction.date} />,
        account && account.institution && account.mask ? (
          <>
            {account.institution.name} x{account.mask}
          </>
        ) : (
          <>Missing account</>
        ),
      ]}
    />
  );
}

TransactionRow.Container = TransactionRowContainer;
export default TransactionRow;
export { TransactionRows, TransactionRowMetadata };
