import React from 'react';
import { Route } from '../Routes';
import { Link } from 'react-router-dom';
import BetterButton from './BetterButton';

export default function MarComHeader({ handleSignIn }) {
  return (
    <header>
      <div className="wrapper">
        <div>
          <Link to={Route.Root}>
            <img src="/logo.svg" width="100" height="24" alt="Lopery logo, which is money in an envelope" className="logo" />
          </Link>
        </div>
        <div>
          <div style={{ display: 'flex', justifyContent: 'end', gap: 8 }}>
            <BetterButton variant="outline-primary" onClick={handleSignIn}>
              Sign in
            </BetterButton>
          </div>
        </div>
      </div>
    </header>
  );
}
