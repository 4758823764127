import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

/**
 * @param {Date} date
 * @returns {dayjs.Dayjs} UTC Timezone Object
 */
function utcDay(date: any = null) {
  dayjs.extend(utc);
  dayjs.extend(isSameOrBefore);
  dayjs.extend(isSameOrAfter);

  if (!date) {
    return dayjs.utc();
  } else if (date.seconds && date.toDate()) {
    return dayjs.utc(date.toDate());
  } else {
    return dayjs.utc(date);
  }
}

export { utcDay };
