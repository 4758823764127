import React from 'react';
import { Accordion, Stack } from 'react-bootstrap';
import BetterAccordionItem from '../components/BetterAccordionItem';
import DollarAmount from '../components/DollarAmount';
import { BsBoxSeam, BsFillRocketTakeoffFill } from 'react-icons/bs';
import BetterButton from '../components/BetterButton';
import ConfettiExplosion from 'react-confetti-explosion';

export default function UserInterfacePage() {
  function handleButtonClick() {
    alert('Button was clicked!');
  }

  return (
    <Stack gap={4}>
      <div style={{ margin: '0 auto', width: 0, height: 0 }}>
        <ConfettiExplosion duration={5000} particleCount={60} />
      </div>
      <section className="floating-card">
        <h3>
          <code>BetterAccordionItem</code>
        </h3>

        <Accordion>
          <BetterAccordionItem eventKey="0" header="Better accordion">
            Lorem ipsum dolor sit amet
          </BetterAccordionItem>
          <BetterAccordionItem eventKey="1" header="With a second line" secondLine="Here’s a little more to see">
            Lorem ipsum dolor sit amet
          </BetterAccordionItem>
          <BetterAccordionItem eventKey="2" header="With a second column" secondColumn={<DollarAmount amount={1000} />}>
            Lorem ipsum dolor sit amet
          </BetterAccordionItem>
          <BetterAccordionItem
            eventKey="3"
            header="With a second column and a second line"
            secondColumn={<DollarAmount amount={1000} />}
            secondLine="Here’s a little more to see"
          >
            Lorem ipsum dolor sit amet
          </BetterAccordionItem>
          <BetterAccordionItem eventKey="3" header="With an icon" icon={<BsBoxSeam />}>
            Lorem ipsum dolor sit amet
          </BetterAccordionItem>
          <BetterAccordionItem eventKey="3" header="When loading" isLoading={true}>
            Lorem ipsum dolor sit amet
          </BetterAccordionItem>
          <BetterAccordionItem
            eventKey="3"
            header="With an error"
            error={new Error('An error occurred')}
            secondLine="Here’s a little more to see"
          >
            Lorem ipsum dolor sit amet
          </BetterAccordionItem>
          <BetterAccordionItem
            eventKey="3"
            header="The kitchen sink"
            icon={<BsBoxSeam />}
            secondLine="Here’s a little more to see"
            secondColumn={<DollarAmount amount={1000} />}
          >
            Lorem ipsum dolor sit amet
          </BetterAccordionItem>
        </Accordion>
      </section>

      <section className="floating-card">
        <h3>
          <code>BetterButton</code>
        </h3>

        <Stack gap={4}>
          <Stack direction="horizontal" gap={2}>
            <BetterButton variant="primary" onClick={handleButtonClick}>
              Primary
            </BetterButton>
            <BetterButton variant="secondary" onClick={handleButtonClick}>
              Secondary
            </BetterButton>
            <BetterButton variant="success" onClick={handleButtonClick}>
              Success
            </BetterButton>
            <BetterButton variant="warning" onClick={handleButtonClick}>
              Warning
            </BetterButton>
            <BetterButton variant="danger" onClick={handleButtonClick}>
              Danger
            </BetterButton>
            <BetterButton variant="info" onClick={handleButtonClick}>
              Info
            </BetterButton>
            <BetterButton variant="light" onClick={handleButtonClick}>
              Light
            </BetterButton>
            <BetterButton variant="dark" onClick={handleButtonClick}>
              Dark
            </BetterButton>
            <BetterButton variant="link" onClick={handleButtonClick}>
              Link
            </BetterButton>
          </Stack>

          <Stack direction="horizontal" gap={2}>
            <BetterButton beforeIcon={<BsFillRocketTakeoffFill />} onClick={handleButtonClick}>
              Before icon
            </BetterButton>
            <BetterButton afterIcon={<BsFillRocketTakeoffFill />} onClick={handleButtonClick}>
              After icon
            </BetterButton>
            <BetterButton beforeIcon={<BsFillRocketTakeoffFill />} afterIcon={<BsFillRocketTakeoffFill />} onClick={handleButtonClick}>
              Before & after icon
            </BetterButton>
          </Stack>

          <Stack direction="horizontal" gap={2}>
            <BetterButton isLoading={true} onClick={handleButtonClick}>
              Loading
            </BetterButton>

            <BetterButton isLoading={true} beforeIcon={<BsFillRocketTakeoffFill />} onClick={handleButtonClick}>
              Loading before icon
            </BetterButton>

            <BetterButton isLoading={true} hideLoadingIndicators beforeIcon={<BsFillRocketTakeoffFill />} onClick={handleButtonClick}>
              Loading hide loading indicators
            </BetterButton>
          </Stack>

          <Stack direction="horizontal" gap={2}>
            <BetterButton isLoading={true} loadingText="Loading text" onClick={handleButtonClick}>
              Loading text
            </BetterButton>

            <BetterButton isLoading={true} loadingText="Loading text" onClick={handleButtonClick} hideLoadingIndicators>
              Loading text with hide loading indicators
            </BetterButton>
          </Stack>

          <Stack direction="horizontal" gap={2}>
            <BetterButton
              isLoading={true}
              beforeIcon={<BsFillRocketTakeoffFill />}
              loadingText="Loading text"
              onClick={handleButtonClick}
              hideLoadingIndicators
            >
              Loading text with hide loading indicators and before icon
            </BetterButton>
          </Stack>
        </Stack>
      </section>
    </Stack>
  );
}
