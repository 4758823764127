import roundCurrency from '../shared/roundCurrency';

export default function formatCurrency(amount: number, options: { showDecimals: boolean } = { showDecimals: false }) {
  const roundedAmount = roundCurrency(amount);

  const formatOptions: Intl.NumberFormatOptions = {
    style: 'currency',
    currency: 'USD',
    currencySign: 'accounting',
  };

  if (!options.showDecimals && Math.floor(roundedAmount) === roundedAmount) {
    formatOptions.minimumFractionDigits = 0;
    formatOptions.maximumFractionDigits = 0;
  }

  return roundedAmount.toLocaleString('en-US', formatOptions);
}
