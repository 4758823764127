import React from 'react';
import useDocumentTitle from '../hooks/useDocumentTitle';
import { deleteField, doc, setDoc } from 'firebase/firestore';
import db from '../firebase';
import { useAuth } from '../App';
import { Badge, Form, Stack } from 'react-bootstrap';
import { useAppLayout } from '../layouts/AppLayout';
import { MONTH_RUNWAY_PREVIOUS_PERIODS, WEEK_RUNWAY_PREVIOUS_PERIODS } from '../shared/constants';

/**
 * Sets the boolean setting on the User document to true when true is passed in
 * or removes the setting when false is passed in.
 * @param value
 */
async function setIsAllowanceBasedOnMovingAverage(uid: string, value: boolean) {
  const userDocRef = doc(db, 'users', uid);
  const dbValue = value || deleteField();
  await setDoc(userDocRef, { isAllowanceBasedOnMovingAverage: dbValue }, { merge: true });
}

function SettingsPage() {
  useDocumentTitle('Settings');

  const {
    uid,
    userData: { isAllowanceBasedOnMovingAverage },
  } = useAuth();

  const { setToast } = useAppLayout();

  return (
    <>
      <h3>Settings</h3>

      <h4>Safe to Spend</h4>

      <p>
        Configure how your monthly and weekly allowance Safe to Spend is determined for your Budget. Please note that changing this setting
        may dramatically affect how you track your spending.
      </p>

      <Form.Check
        checked={!isAllowanceBasedOnMovingAverage}
        id="allowance-mode-discretionary-income"
        label={
          <>
            <div>
              <strong>Net income-based (default)</strong>
            </div>
            <div className="small-font">Your recurring income minus recurring expenses, plans, and carryover</div>
          </>
        }
        name="allowance-mode"
        onChange={() => {
          setIsAllowanceBasedOnMovingAverage(uid, false);
          setToast('Setting saved!');
        }}
        type="radio"
      />
      <Form.Check
        checked={isAllowanceBasedOnMovingAverage}
        id="allowance-mode-moving-average"
        label={
          <>
            <Stack gap={2} direction="horizontal">
              <strong>Average spending-based</strong>
              <Badge>Beta</Badge>
            </Stack>

            <div className="small-font">
              Based on a {WEEK_RUNWAY_PREVIOUS_PERIODS}-week or {MONTH_RUNWAY_PREVIOUS_PERIODS}-month moving average of your spending (note
              this calculation does not include recurring income, recurring expenses, plans, or carryover)
            </div>
          </>
        }
        name="allowance-mode"
        onChange={() => {
          setIsAllowanceBasedOnMovingAverage(uid, true);
          setToast('Setting saved!');
        }}
        type="radio"
      />
    </>
  );
}

export default SettingsPage;
