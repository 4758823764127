import { useEffect, useState } from 'react';
import {
  onSnapshot,
  query,
  collection,
  where,
  orderBy,
  Unsubscribe,
  FirestoreError,
  QueryConstraint,
  OrderByDirection,
} from 'firebase/firestore';
import { useDeepCompareEffect } from 'use-deep-compare';
import { chunk } from 'underscore';
import { Dayjs } from 'dayjs';
import { useAuth } from '../App';
import db from '../firebase';
import isTransactionRecurring from '../shared/isTransactionRecurring';
import sortTransactions from '../shared/sortTransactions';
import { isTransactionExpense, isTransactionIncome } from '../shared/isTransactionExpense';
import { AccountDocData, TransactionDocData } from '../types';

interface Options {
  startDate?: Dayjs;
  endDate?: Dayjs;
  accounts?: AccountDocData[];
  orderByDirection?: OrderByDirection;
}

export default function useTransactions(
  { startDate, endDate, accounts, orderByDirection = 'desc' }: Options = {
    orderByDirection: 'desc',
  },
) {
  const { uid } = useAuth();
  const [transactionsById, setTransactionsById] = useState<{
    [id: string]: TransactionDocData;
  }>({});
  const [unsubscribes, setUnsubcribes] = useState<Unsubscribe[]>([]);
  const [error, setError] = useState<FirestoreError>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [, setIsUnsubscribed] = useState<boolean>(true);

  const accountIds = accounts?.map((account) => account.accountId);

  function loadTransactions({ accountIds }: { accountIds?: string[] } = {}) {
    setIsLoading(true);

    // Note: this ordering is occurring on batched queries due
    // to the limitation of in queries only supporting 10 items.
    // In order, for the final result to be ordered correctly, it
    // will need to be sorted again below.
    const queryConstraints: QueryConstraint[] = [orderBy('date', orderByDirection)];

    if (accountIds?.length > 0) queryConstraints.push(where('accountId', 'in', accountIds));

    if (startDate) queryConstraints.push(where('date', '>=', startDate.toDate()));

    if (endDate) queryConstraints.push(where('date', '<=', endDate.toDate()));

    const transactionsQuery = query(collection(db, 'users', uid, 'transactions'), ...queryConstraints);

    const unsubscribe = onSnapshot(
      transactionsQuery,
      ({ docs }) => {
        const nextTransactionsById = {};
        const nextTransactions = docs.map((doc) => doc.data());

        for (const nextTransaction of nextTransactions) nextTransactionsById[nextTransaction.transactionId] = nextTransaction;

        // If the query params have changed, the UI may still be showing
        // a list of transactions that needs to change. Rather than replacing
        // the list with [], replace it with the first results of Firestore's
        // `onSnapshot`, then append as additional snapshots come in.
        setIsUnsubscribed((prevIsUnsubscribed) => {
          setTransactionsById((prevTransactionsById) => {
            if (prevIsUnsubscribed) return nextTransactionsById;
            return { ...prevTransactionsById, ...nextTransactionsById };
          });

          return false;
        });

        setIsLoading(false);
      },
      (error) => {
        setError(error);
        setIsLoading(false);
      },
    );

    // No problem being generous here. If the `unsubscribe` function
    // is stale it has no unintended consequences if called.
    setUnsubcribes((prevUnsubscribes) => [...prevUnsubscribes, unsubscribe]);
  }

  function unsubscribe() {
    setIsUnsubscribed(true);
    unsubscribes.forEach((unsubscribe) => unsubscribe());
  }

  let effectDeps = [];
  let effectFn = useEffect;
  if (startDate) effectDeps.push(startDate.valueOf());
  if (endDate) effectDeps.push(endDate.valueOf());

  // If `accountIds` are passed in, we want our hook to re-run when
  // they change. To do this, use `useDeepCompareEffect`, which will
  // compare the actual account IDs (versus the array reference).
  if (accountIds) {
    effectDeps = [...effectDeps, accountIds];
    effectFn = useDeepCompareEffect;
  }

  effectFn(() => {
    // Safe to run initially. Subsequent changes to deps should reset
    // the query and results.
    unsubscribe();

    if (accountIds) {
      // If `accountIds` are provided but they're empty, don't attempt
      // to fetch transactions at all. If you want to filter to "no accounts"
      // then leave off the `accounts` argument altogether.
      if (accountIds.length === 0) return;

      for (const chunkOfAccountIds of chunk(accountIds, 10)) loadTransactions({ accountIds: chunkOfAccountIds });
    } else loadTransactions();

    return unsubscribe;
  }, effectDeps); // eslint-disable-line react-hooks/exhaustive-deps

  // Once all the transactions are collated, they can be finally sorted
  const transactions = Object.values(transactionsById).sort((a, b) =>
    sortTransactions(
      { amount: a.amount, name: a.name, unixTimestamp: a.date.toMillis() },
      { amount: b.amount, name: b.name, unixTimestamp: b.date.toMillis() },
      orderByDirection,
    ),
  );

  const recurring = transactions.filter(isTransactionRecurring);
  const incomes = transactions.filter(isTransactionIncome);
  const expenses = transactions.filter(isTransactionExpense);
  const recurringExpenses = expenses.filter(isTransactionRecurring);
  const recurringIncomes = incomes.filter(isTransactionRecurring);

  return {
    error,
    expenses,
    incomes,
    isLoading,
    recurring,
    recurringExpenses,
    recurringIncomes,
    transactions,
    transactionsById,
  };
}
