import React from 'react';
import { useEffect, useState } from 'react';
import { collection, getDocs, limit, orderBy, query } from 'firebase/firestore';
import { useAuth } from '../App';
import dayjs from 'dayjs';
import useTransactions from '../hooks/useTransactions';
import Loader from './Loader';
import pluralize from '../shared/pluralize';
import { TransactionRows } from '../components/TransactionRow';
import { Alert, Stack } from 'react-bootstrap';
import useAccounts from '../hooks/useAccounts';
import db, { syncTransactions } from '../firebase';
import BigSpinner from './BigSpinner';
import BetterButton from './BetterButton';
import { TransactionDownloadDocData } from '../types';

export default function Transactions() {
  const { uid } = useAuth();
  const [latestTransactionDownload, setLatestTransactionDownload] = useState<TransactionDownloadDocData>();
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadingError, setDownloadingError] = useState(null);
  const { accountsById } = useAccounts();
  const { transactions, isLoading } = useTransactions();

  useEffect(() => {
    getLatestTransactionDownload().catch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getLatestTransactionDownload() {
    const querySnapshot = await getDocs(
      query(collection(db, 'users', uid, 'transactionDownloads'), orderBy('createdAt', 'desc'), limit(1)),
    );

    if (querySnapshot.size === 1) setLatestTransactionDownload(querySnapshot.docs[0].data() as TransactionDownloadDocData);
  }

  const handleDownloadTransactions = async () => {
    try {
      setIsDownloading(true);
      await syncTransactions();
      await getLatestTransactionDownload();
    } catch (error) {
      setDownloadingError(error);
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <>
      <Loader isLoading={isLoading || isDownloading} />

      {downloadingError && <Alert variant="danger">{downloadingError.message}</Alert>}

      {isLoading ? (
        <BigSpinner />
      ) : (
        <Stack gap={4}>
          <section style={{ display: 'flex' }}>
            <div style={{ flex: 1 }}>
              <div className="cap-first-letter">{pluralize(transactions.length, 'transaction')}</div>

              {latestTransactionDownload && (
                <div className="small-font text-muted">
                  Last downloaded {dayjs(latestTransactionDownload.createdAt.toDate()).format('MMM D, YYYY [at] h:mma')}
                </div>
              )}
            </div>
            <div>
              <BetterButton variant="outline-primary" size="sm" isLoading={isDownloading} onClick={handleDownloadTransactions}>
                Get transactions
              </BetterButton>
            </div>
          </section>

          <section>
            <TransactionRows accountsById={accountsById} transactions={transactions} />
          </section>
        </Stack>
      )}
    </>
  );
}
